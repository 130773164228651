import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { FlyControls } from "three/examples/jsm/controls/FlyControls.js"
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js"
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js"
import { CubeTextureLoader } from "three"
import gsap from "gsap"

const loadingContainer = document.querySelector(".loadingContainer")
const loadingType = document.querySelector(".loader--percent")

const loadingManager = new THREE.LoadingManager(
    () =>{
        gsap.to(overlayMat.uniforms.uAlpha,{
            duration: 5, 
            value: 0,
            onComplete: function(){
                document.getElementById("instructions").style.visibility = "visible"
                document.getElementById("instructions").style.animation="opacityFadeIn 1s ease 0s 1 forwards"  
                loadingContainer.style.opacity = 0
                loadingContainer.style.zIndex = -1
            }
        })
        loadingContainer.style.opacity = 0
    },
    (itemURL, itemsLoaded, itemsTotal) =>{
        const progressRatio = itemsLoaded / itemsTotal
        let percent = (itemsLoaded / itemsTotal) * 100
        let percentFixed = percent.toFixed(2)
        loadingType.innerHTML = `${percentFixed}%`
        console.log(percentFixed)
    },
    (e) =>{
        console.log(e)
    }
)
const textureLoader = new THREE.TextureLoader(loadingManager)
const modelLoader = new FBXLoader(loadingManager)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

const envMapTex = cubeTextureLoader.load([
    "./planetMaps/Env_Stars/px.png",
    "./planetMaps/Env_Stars/nx.png",
    "./planetMaps/Env_Stars/py.png",
    "./planetMaps/Env_Stars/ny.png",
    "./planetMaps/Env_Stars/pz.png",
    "./planetMaps/Env_Stars/nz.png",

])

const foilNormal = textureLoader.load("./textures/foil_n.png")
foilNormal.wrapS = THREE.RepeatWrapping
foilNormal.wrapT = THREE.RepeatWrapping
foilNormal.repeat.set(5, 5)

//create a Three.js Scene 
const scene = new THREE.Scene() 
scene.background = envMapTex
scene.backgroundRotation.y = Math.PI * 0.65

// Loading screen
const overlayMat = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:{
        uAlpha: {value: 1}
    },
    vertexShader:`
        void main(){
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader:`
        uniform float uAlpha;
        void main(){
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `
})
const overlayGeo = new THREE.PlaneGeometry(2,2,1,1)
const overlayMesh = new THREE.Mesh(overlayGeo, overlayMat)
scene.add(overlayMesh)

//materials
const basicMat = new THREE.MeshBasicMaterial({
    color: 0xffffff,
})

const grayMat = new THREE.MeshStandardMaterial({
    color: 0x3c3c3c,
})

const goldFoilMat = new THREE.MeshStandardMaterial({
    color: 0xd4af37,
    roughness: 0.2,
    metalness: 1,
    normalMap: foilNormal

})

const redMat = new THREE.MeshPhysicalMaterial({
    color: 0xff0000,
    roughness: .2,
    metalness: .4
})

const sunColor = textureLoader.load("./planetMaps/sun_2k.jpg")
const sunMat = new THREE.MeshPhysicalMaterial({
    color:0xffffff,
    emissive: 0xffffff,
    emissiveIntensity: 1,
    emissiveMap: sunColor
})

const geometry = new THREE.BoxGeometry(1, 1, 1) 
const material = new THREE.MeshStandardMaterial({
    color: 0xffffff,
    //roughness: 0.5,
}) 

const planeGeo = new THREE.PlaneGeometry(200,200, 2, 2)
const planeMesh = new THREE.Mesh(planeGeo, material)
planeMesh.castShadow=true
planeMesh.receiveShadow=true
planeMesh.position.set(0,-40,0)
planeMesh.rotation.x = -90 * (Math.PI / 180)
//scene.add(planeMesh)

// Orbit Materials
const orbitPathMat = new THREE.LineBasicMaterial({
    color: 0x555555,
    linewidth: 1
})

let orbitPaths = []
// Contact Planet
let contactOrbitPath
const contactOrbit = new THREE.Group()
scene.add(contactOrbit)
const contactAngle = 10
const contactRadians = contactAngle * (Math.PI/180)
const contactOrbitAmplitude = 30
const contactOrbitSpeed = 0
const contactOrbitOffset = Math.PI * .75

const contactMesh = new THREE.Mesh(
    new THREE.SphereGeometry(1, 16, 16), 
    material
)
contactMesh.castShadow = true
contactMesh.receiveShadow = true
contactMesh.rotation.order = "ZYX"
scene.add(contactMesh)

let emailOrbitPath
const emailAmplitude = 6
const emailSpeed = .1
const emailOffset = Math.PI
const emailGroup = new THREE.Group()

let youtubeOrbitPath
const youtubeAmplitude = 10
const youtubeSpeed = .1
const youtubeOffset = 0
const youtubeGroup = new THREE.Group()

let instaOrbitPath
const instaAmplitude = 15
const instaSpeed = .1
const instaOffset = Math.PI * 0.25
const instaGroup = new THREE.Group()

let linkedInOrbitPath
const linkedInAmplitude = 19
const linkedInSpeed = .1
const linkedInOffset = Math.PI * 1.25
const linkedInGroup = new THREE.Group()

let cassiniOrbitPath
const cassiniAmplitude = 3
const cassiniSpeed = .1
const cassiniOffset = Math.PI * 1
const cassiniGroup = new THREE.Group()

// photography planet
let photoOrbitPath
const photographyOrbit = new THREE.Group()
scene.add(photographyOrbit)
const photoAngle = -23.5
const photoRadians = photoAngle * (Math.PI / 180)
const photoAmplitude = 70
const photoSpeed = 0
const photoOffset = Math.PI * 1.25

let petOrbitPath
const petAmplitude = 9
const petSpeed = .1
const petOffset = 0

let productOrbitPath
const productAmplitude = 11
const productSpeed = .1
const productOffset = Math.PI * 0.375

let wildlifeOrbitPath
const wildlifeAmplitude = 15
const wildlifeSpeed = .1
const wildlifeOffset = Math.PI * 0.75

let portraitOrbitPath
const portraitAmplitude = 19
const portraitSpeed = .1
const portraitOffset = Math.PI

let landscapeOrbitPath
const landscapeAmplitude = 25
const landscapeSpeed = .1
const landscapeOffset = Math.PI * 1.4

// Animation Planet
let animOrbitPath
const animOrbit = new THREE.Group()
scene.add(animOrbit)
const animTilt = 20 * (Math.PI / 180)
const animOrbitAmplitude = 100
const animSpeed = .0005
const animOffset = Math.PI * .05

let mayaOrbitPath
const mayaAmplitude = 6
const mayaSpeed = 0.1
const mayaOffset = 0

let aeOrbitPath
const aeAmplitude = 15
const aeSpeed = 0.1
const aeOffset = 0


modelLoader.load(
    "./models/nurbsCircle.fbx",
    (fbx) =>{
        console.log("Curve load success")
        fbx.traverse((child)=>{
            if(child instanceof THREE.Line){
                child.material = orbitPathMat
                for(let i = 0; i < 14; i ++){
                    const childClone = child.clone()
                    scene.add(childClone)
                    orbitPaths.push(childClone)
                }
                [
                    contactOrbitPath,
                    emailOrbitPath, 
                    youtubeOrbitPath, 
                    instaOrbitPath, 
                    linkedInOrbitPath,
                    photoOrbitPath,
                    petOrbitPath,
                    productOrbitPath,
                    wildlifeOrbitPath,
                    portraitOrbitPath,
                    landscapeOrbitPath,
                    animOrbitPath,
                    mayaOrbitPath,
                    aeOrbitPath,
                ] = orbitPaths
            }
        })

        setContactOrbits()
        setPhotoOrbits()
        setAnimOrbits()

    },
    ()=>{
        console.log("loading")
    },
    (e)=>{
        console.log(e)
    }
)

function setContactOrbits(){
    contactOrbitPath.scale.set(contactOrbitAmplitude, contactOrbitAmplitude, contactOrbitAmplitude)

    emailOrbitPath.scale.set(emailAmplitude, emailAmplitude, emailAmplitude)
    emailOrbitPath.rotation.set(0, 0, contactAngle * (Math.PI/180))

    youtubeOrbitPath.scale.set(youtubeAmplitude, youtubeAmplitude, youtubeAmplitude)
    youtubeOrbitPath.rotation.set(0, 0, contactAngle * (Math.PI/180))

    instaOrbitPath.scale.set(instaAmplitude, instaAmplitude, instaAmplitude)
    instaOrbitPath.rotation.set(0, 0, contactAngle * (Math.PI/180))

    linkedInOrbitPath.scale.set(linkedInAmplitude, linkedInAmplitude, linkedInAmplitude)
    linkedInOrbitPath.rotation.set(0, 0, contactAngle * (Math.PI/180))
}

function setPhotoOrbits(){
    photoOrbitPath.scale.set(photoAmplitude,photoAmplitude,photoAmplitude,)
    petOrbitPath.scale.set(petAmplitude, petAmplitude, petAmplitude)
    petOrbitPath.rotation.set(0, 0, photoAngle * (Math.PI/180))

    productOrbitPath.scale.set(productAmplitude, productAmplitude, productAmplitude)
    productOrbitPath.rotation.set(0, 0, photoAngle * (Math.PI/180))

    wildlifeOrbitPath.scale.set(wildlifeAmplitude, wildlifeAmplitude, wildlifeAmplitude)
    wildlifeOrbitPath.rotation.set(0, 0, photoAngle * (Math.PI/180))

    portraitOrbitPath.scale.set(portraitAmplitude, portraitAmplitude, portraitAmplitude)
    portraitOrbitPath.rotation.set(0, 0, photoAngle * (Math.PI/180))

    landscapeOrbitPath.scale.set(landscapeAmplitude, landscapeAmplitude, landscapeAmplitude)
    landscapeOrbitPath.rotation.set(0, 0, photoAngle * (Math.PI/180))
}

function setAnimOrbits(){
    animOrbitPath.scale.set(animOrbitAmplitude, animOrbitAmplitude, animOrbitAmplitude)
    animOrbitPath.rotation.set(0, 0, 0)

    mayaOrbitPath.scale.set(mayaAmplitude, mayaAmplitude, mayaAmplitude)
    mayaOrbitPath.rotation.set(0, 0, animTilt)

    aeOrbitPath.scale.set(aeAmplitude, aeAmplitude, aeAmplitude)
    aeOrbitPath.rotation.set(0,0, animTilt)
}


// Meshes
const floorMesh = new THREE.Mesh(
    new THREE.PlaneGeometry(300, 300, 2, 2),
    new THREE.MeshStandardMaterial()
)

floorMesh.receiveShadow = true
floorMesh.rotation.set(Math.PI * -0.5, 0, 0)
floorMesh.position.set(0, -10, 0)

const sunMesh = new THREE.Mesh(
    new THREE.SphereGeometry(1, 32, 32), 
    sunMat
)
sunMesh.position.set(0,0,0)
scene.add(sunMesh)

//sunMesh.castShadow = true

const cameraMesh = new THREE.Mesh(
    new THREE.SphereGeometry(5, 16, 16), 
    material
)
cameraMesh.castShadow = true
cameraMesh.receiveShadow = true
scene.add(cameraMesh)

const animMesh = new THREE.Mesh(
    new THREE.BoxGeometry(5,5,5), 
    material
)
animMesh.castShadow = true
animMesh.receiveShadow = true
scene.add(animMesh)

const animMoonA = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 10),
    material
)

animMoonA.castShadow = true
animMoonA.receiveShadow = true
scene.add(animMoonA)

const animMoonB = new THREE.Mesh(
    new THREE.SphereGeometry(1, 10, 10),
    material
)

animMoonB.castShadow = true
animMoonB.receiveShadow = true
scene.add(animMoonB)

modelLoader.load(
    "./models/Cassini.fbx",
    (fbx)=>{
        
        fbx.traverse((child)=>{
            if(child instanceof THREE.Mesh){
                let geo = child.geometry
                console.log(child.name)
                if(child.name == "dish_Geo"){
                    let mesh = new THREE.Mesh(geo, grayMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    cassiniGroup.add(mesh)
                }
                if(child.name == "aluminum_Geo"){
                    let mesh = new THREE.Mesh(geo, grayMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    cassiniGroup.add(mesh)
                }
                if(child.name == "goldFoil_Geo"){
                    let mesh = new THREE.Mesh(geo, goldFoilMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    cassiniGroup.add(mesh)
                }
                if(child.name == "dark_Geo"){
                    let mesh = new THREE.Mesh(geo, grayMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    cassiniGroup.add(mesh)
                }
                if(child.name == "blackFloil_Geo"){
                    let mesh = new THREE.Mesh(geo, grayMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    cassiniGroup.add(mesh)
                }
                if(child.name == "vents_Geo"){
                    let mesh = new THREE.Mesh(geo, grayMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    cassiniGroup.add(mesh)
                }
                if(child.name == "white_Geo"){
                    let mesh = new THREE.Mesh(geo, grayMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    cassiniGroup.add(mesh)
                }
            }
        })
    },
    ()=>{
        console.log("loading")
    },
    (e)=>{
        console.log(e)
    }
)


modelLoader.load(
    "./models/Social_Media_Icons_v02.fbx",
    (fbx) =>{
        console.log(fbx)
        fbx.traverse((child)=>{
            if(child instanceof THREE.Mesh){
                let geo = child.geometry
                console.log(child.name)
                if(child.name == "YT_Outside_Geo"){
                    let mesh = new THREE.Mesh(geo, redMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    youtubeGroup.add(mesh)
                }
                if(child.name == "YT_Inside_Geo"){
                     let mesh = new THREE.Mesh(geo,basicMat)
                     mesh.castShadow = true
                     mesh.receiveShadow = true
                     youtubeGroup.add(mesh)
                }
                if(child.name == "IG_Inside_Geo"){
                    let mesh = new THREE.Mesh(geo, basicMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    instaGroup.add(mesh)
                }
                if(child.name == "IG_Outside_Geo"){
                    let mesh = new THREE.Mesh(geo, redMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    instaGroup.add(mesh)
                }
                if(child.name == "Linkedin_Inside_Geo"){
                    let mesh = new THREE.Mesh(geo, basicMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    linkedInGroup.add(mesh)
                }
                if(child.name == "Linkedin_Outside_Geo"){
                    let mesh = new THREE.Mesh(geo, redMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    linkedInGroup.add(mesh)
                }
                if(child.name == "Email_Outside_Geo"){
                    let mesh = new THREE.Mesh(geo, redMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    emailGroup.add(mesh)
                }
                if(child.name == "Email_Inside_Geo"){
                    let mesh = new THREE.Mesh(geo, basicMat)
                    mesh.castShadow = true
                    mesh.receiveShadow = true
                    emailGroup.add(mesh)
                }
                //scene.add(mesh)
            }
        })
    }
)
cassiniGroup.scale.set(.003, .003, .003)
cassiniGroup.rotation.order = "ZYX"
cassiniGroup.rotation.z = Math.PI * 0.5
scene.add(cassiniGroup)

youtubeGroup.scale.x = 0.5
youtubeGroup.scale.y = 0.5
youtubeGroup.scale.z = 0.5
youtubeGroup.rotation.order = "ZYX"
youtubeGroup.rotation.z = contactRadians
scene.add(youtubeGroup)

instaGroup.scale.x = 0.5
instaGroup.scale.y = 0.5
instaGroup.scale.z = 0.5
instaGroup.rotation.order = "ZYX"
//instaGroup.rotation.x = 90 * (Math.PI/180)
instaGroup.rotation.z = contactRadians

scene.add(instaGroup)

// const linkedInMesh = new THREE.Mesh(
//     new THREE.BoxGeometry(1, 1, 1), 
//     material
// )
// linkedInMesh.castShadow = true
// linkedInMesh.receiveShadow = true
// linkedInGroup.add(linkedInMesh)
linkedInGroup.scale.set(0.25, 0.25, 0.25)
linkedInGroup.rotation.z = contactRadians
scene.add(linkedInGroup)

emailGroup.scale.set(0.25, 0.25, 0.25)
emailGroup.rotation.z = contactRadians
scene.add(emailGroup)

const dogColor = textureLoader.load("./textures/Labrador.jpg")
let dogModel = new THREE.Mesh()

modelLoader.load(
    "./models/dogRun.fbx",
    (fbx) =>{
        console.log(fbx)
        fbx.traverse((child)=>{
            if(child instanceof THREE.Mesh){
                let geo = child.geometry
                let mat = new THREE.MeshBasicMaterial({
                    color:0xffffff,
                    map: dogColor,
                    //emissive: 0xffffff,
                    //emissiveIntensity: 1,
                    //emissiveMap: dogColor
                })
                dogModel = new THREE.Mesh(geo, mat)
                dogModel.castShadow = true
                dogModel.receiveShadow = true
                dogModel.scale.x = 0.015
                dogModel.scale.y = 0.015
                dogModel.scale.z = 0.015
                scene.add(dogModel)
            }
        })
    }
)

const productMoon = new THREE.Mesh(
    new THREE.SphereGeometry(1, 16, 16), 
    material
)
productMoon.castShadow = true
productMoon.receiveShadow = true

scene.add(productMoon)

const wildlifeMoon = new THREE.Mesh(
    new THREE.SphereGeometry(1, 16, 16),
    material
)
wildlifeMoon.castShadow = true
wildlifeMoon.receiveShadow = true
scene.add(wildlifeMoon)

const portraitMoon = new THREE.Mesh(
    new THREE.SphereGeometry(1, 16, 16),
    material
)
portraitMoon.castShadow = true
portraitMoon.receiveShadow = true
scene.add(portraitMoon)

const landscapeMoon = new THREE.Mesh(
    new THREE.SphereGeometry(1, 16, 16),
    material
)
landscapeMoon.castShadow = true
landscapeMoon.receiveShadow = true
scene.add(landscapeMoon)

// Lights
const PointLight = new THREE.PointLight(0xffffcc, 2000, 400)
PointLight.castShadow = true
PointLight.shadow.mapSize.width = 4096
PointLight.shadow.mapSize.height = 4096
PointLight.position.set(0 ,0, 0)
scene.add(PointLight)

const ambientLight = new THREE.AmbientLight(0xffffff, .02)
scene.add(ambientLight)

const spotLight = new THREE.SpotLight(0xffffff, 200,80,Math.PI * 0.3,1,2)
const spotLightHelper = new THREE.SpotLightHelper(spotLight)
//scene.add(spotLightHelper)
//scene.add(spotLight, spotLight.target)

spotLight.position.set(0, 20, 0)
spotLight.target.position.set(0, 0, 0)

spotLight.castShadow = true
spotLight.shadow.mapSize.width = 4096
spotLight.shadow.mapSize.height = 4096

//spotLightHelper.update()
const areaLight = new THREE.RectAreaLight(0xffffff, .25, 200, 200)
areaLight.position.set(0, 20, 0)
areaLight.rotation.set(Math.PI * -0.5, 0, 0)
//scene.add(areaLight)

const areaLightHelper = new RectAreaLightHelper(areaLight)
//scene.add(areaLightHelper)

// Raycaster
const raycaster = new THREE.Raycaster()

//Camera 
const aspectRatio = { 
    width: 800, 
    height: 600, 
} 

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener("resize", () => {
    sizes.width = innerWidth
    sizes.height = innerHeight
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Mouse
const mouse = new THREE.Vector2()
window.addEventListener("mousemove", (e) =>{
    mouse.x = e.clientX / sizes.width * 2 - 1
    mouse.y = -(e.clientY / sizes.height) * 2 + 1
})

// Handles 3D object clicking
window.addEventListener("click", () => {
    if (currentIntersect){
        switch(currentIntersect.object){
            case sunMesh:
                clickAbout(sunMesh)
                break
            case contactMesh:
                clickContacts(contactMesh)
                break
            case cameraMesh:
                clickPhotography(cameraMesh)
                break
            case animMesh:
                clickAnimation(animMesh)
                break
            default:
                break
        }
    }
})

function hideAllSections() {
    const sections = ["aboutDiv", "contactDiv", "photographyDiv", "animationDiv"]
    sections.forEach(sectionId => {
        //document.getElementById(sectionId).style.animation="fadeOut 1s ease 0s 1 forwards";
        //document.getElementById(sectionId).style.visibility = "hidden";
        const element = document.getElementById(sectionId)
        element.style.animation="fadeOut 1s ease 0s 1 forwards"
        element.addEventListener("animationend", function(event){
            if(event.animationName ==="fadeOut"){
                element.style.visibility="hidden"
            }
        }, {once: true})
    })
}

function clickSection(sectionId, targetObject, posOffsetX, posOffsetY, posOffsetZ, rotOffsetY) {
    hideAllSections() // Hide all sections before showing the target one

    // Set section visibility
    //document.getElementById(sectionId).style.visibility = "visible"

    // Animate camera position with GSAP
    gsap.to(camera.position, {
        x: targetObject.position.x + posOffsetX,
        y: targetObject.position.y + posOffsetY,
        z: targetObject.position.z + posOffsetZ,
        duration: 5,
        ease: "power2.inOut",
        onComplete: function(){
            document.getElementById(sectionId).style.visibility = "visible"
            document.getElementById(sectionId).style.animation="fadeIn 1s ease 0s 1 forwards"
        }
    })
    
    gsap.to(orbitControls.target,{
        x: targetObject.position.x + rotOffsetY,
        y: targetObject.position.y,
        z: targetObject.position.z,
        duration: 4,
        ease: "power2.inOut",
        onUpdate: function(){
            orbitControls.update()
        }
    })
}

function clickAbout(targetObject){
    console.log("Click Sun")
    clickSection("aboutDiv", targetObject, 3, 3, 10, 0)
}

function clickContacts(targetObject){
    clickSection("contactDiv", targetObject, 0, -3, 20, -3)
}

function clickPhotography(targetObject){
    console.log("clicked photography")
    clickSection("photographyDiv", targetObject, 0, 6, 40, -10)
}

function clickAnimation(targetObject){
    console.log("clicked animation")
    clickSection("animationDiv", targetObject, -20, 3, 30, -8)
}

//HTML button functions
document.getElementById("aboutButton").addEventListener("click", function(){
    clickAbout(sunMesh)
})

document.getElementById("animationButton").addEventListener("click", function(){
    clickAnimation(animMesh)
})

document.getElementById("photographyButton").addEventListener("click", function(){
    clickPhotography(cameraMesh)
})

document.getElementById("contactButton").addEventListener("click", function(){
    clickContacts(contactMesh)
})

document.getElementById("petPhotographyButton").addEventListener("click", function(){
    window.location.href="/pet-photography.html"
})

document.getElementById("portraitPhotographyButton").addEventListener("click", function(){
    window.location.href="/portrait-photography.html"
})

const closeButtons = document.querySelectorAll(".rightSideButton")
closeButtons.forEach(button =>{
    button.addEventListener("click", function(){
        console.log("close Button clicked")
        hideAllSections()
    })
})

// Camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height) 

camera.position.z = 140
camera.position.y = 10
camera.position.x = 0
camera.lookAt(0,0,0)
scene.add(camera) 

//renderer 
//get canvas DOM 
const canvas = document.querySelector(".webgl") 
const renderer = new THREE.WebGLRenderer({ 
    canvas: canvas,
    alpha: true,
}) 
//set size 
renderer.setSize(sizes.width, sizes.height) 
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true
renderer.physicallyCorrectLights = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
//render scene 

const orbitControls = new OrbitControls(camera, canvas)

const flyControls = new FlyControls(camera, canvas)
flyControls.movementSpeed = 4
flyControls.domElement = canvas
flyControls.rollSpeed = Math.PI / 24
flyControls.dragToLook = false

let currentIntersect
const clickable = [sunMesh, contactMesh, cameraMesh, animMesh]
const clock = new THREE.Clock() 
//animation 
//tick is basically an update function 
const tick = () =>{
    const delta = clock.getDelta()
    const elapsedTime = clock.getElapsedTime()

    sunMesh.rotation.y += delta * 0.01

    //attr = amp(sin(time + offset))
    contactOrbit.position.x = contactOrbitAmplitude * Math.sin((elapsedTime * contactOrbitSpeed) + contactOrbitOffset)
    contactOrbit.position.y = 0
    contactOrbit.position.z = contactOrbitAmplitude * Math.cos((elapsedTime * contactOrbitSpeed) + contactOrbitOffset)
    
    photographyOrbit.position.x = photoAmplitude * Math.sin((elapsedTime * photoSpeed) + photoOffset)
    photographyOrbit.position.y = 1 * Math.sin(elapsedTime * photoSpeed) + 0
    photographyOrbit.position.z = photoAmplitude * Math.cos((elapsedTime * photoSpeed) + photoOffset)
    
    //animOrbit
    animOrbit.position.x = animOrbitAmplitude * Math.sin((elapsedTime * animSpeed) + animOffset)
    //animOrbit.position.y = animOrbitAmplitude * Math.sin((animTilt) * Math.sin((elapsedTime * animSpeed)))
    animOrbit.position.z = animOrbitAmplitude * Math.cos((elapsedTime * animSpeed) + animOffset)

    // contanct Orbits
    contactMesh.position.x = contactOrbit.position.x 
    contactMesh.position.y = contactOrbit.position.y 
    contactMesh.position.z = contactOrbit.position.z 

    if(emailOrbitPath){
        emailOrbitPath.position.x = contactOrbit.position.x
        emailOrbitPath.position.y = contactOrbit.position.y
        emailOrbitPath.position.z = contactOrbit.position.z
    }

    if(youtubeOrbitPath){
        youtubeOrbitPath.position.x = contactOrbit.position.x
        youtubeOrbitPath.position.y = contactOrbit.position.y
        youtubeOrbitPath.position.z = contactOrbit.position.z
    }
    
    cassiniGroup.position.x = contactOrbit.position.x + (cassiniAmplitude * Math.sin((elapsedTime * cassiniSpeed) + cassiniOffset))
    cassiniGroup.position.z = contactOrbit.position.z + (cassiniAmplitude * Math.cos((elapsedTime * cassiniSpeed) + cassiniOffset))
    cassiniGroup.position.y = contactOrbit.position.y + (cassiniAmplitude * Math.sin((elapsedTime * cassiniSpeed) + cassiniOffset))

    cassiniGroup.rotation.y = elapsedTime * 0.25
    cassiniGroup.rotation.x = elapsedTime * 0.005

    const youtubeMoonRadius = (Math.sin(contactRadians) * youtubeAmplitude) / (Math.tan(contactRadians))

    //youtubeGroup.rotation.set(90 * (Math.PI/180), 90 * (Math.PI/180), 0)
    youtubeGroup.rotation.y = elapsedTime * .25
    youtubeGroup.position.x = contactOrbit.position.x + (youtubeMoonRadius * Math.sin((elapsedTime * youtubeSpeed) + youtubeOffset))
    youtubeGroup.position.z = contactOrbit.position.z + (youtubeAmplitude * Math.cos((elapsedTime * youtubeSpeed) + youtubeOffset))
    youtubeGroup.position.y = contactOrbit.position.y + (youtubeAmplitude * Math.sin(contactRadians) * Math.sin((elapsedTime * youtubeSpeed) + youtubeOffset))

    if(instaOrbitPath){
        instaOrbitPath.position.x = contactOrbit.position.x
        instaOrbitPath.position.y = contactOrbit.position.y
        instaOrbitPath.position.z = contactOrbit.position.z
    }

    const instaMoonRadius = (Math.sin(contactRadians) * instaAmplitude) / (Math.tan(contactRadians))
    //instaGroup.rotation.set(90 * (Math.PI/180), 90 * (Math.PI/180), 0)
    instaGroup.rotation.y = elapsedTime * .8
    instaGroup.position.x = contactOrbit.position.x + (instaMoonRadius * Math.sin((elapsedTime * instaSpeed) + instaOffset))
    instaGroup.position.z = contactOrbit.position.z + (instaAmplitude * Math.cos((elapsedTime * instaSpeed) + instaOffset))
    instaGroup.position.y = contactOrbit.position.y + (instaAmplitude * Math.sin(contactRadians) * Math.sin((elapsedTime * instaSpeed) + instaOffset))

    if(linkedInOrbitPath){
        linkedInOrbitPath.position.x = contactOrbit.position.x
        linkedInOrbitPath.position.y = contactOrbit.position.y
        linkedInOrbitPath.position.z = contactOrbit.position.z
    }

    const linkedInMoonRadius = (Math.sin(contactRadians) * linkedInAmplitude) / (Math.tan(contactRadians))
    linkedInGroup.rotation.set(0, 0, contactRadians)
    linkedInGroup.position.x = contactOrbit.position.x + (linkedInMoonRadius * Math.sin((elapsedTime * linkedInSpeed) + linkedInOffset))
    linkedInGroup.position.z = contactOrbit.position.z + (linkedInAmplitude * Math.cos((elapsedTime * linkedInSpeed) + linkedInOffset))
    linkedInGroup.position.y = contactOrbit.position.y + (linkedInAmplitude * Math.sin(contactRadians) * Math.sin((elapsedTime * linkedInSpeed) + linkedInOffset))

//
    const emailMoonRadius = (Math.sin(contactRadians) * emailAmplitude) / (Math.tan(contactRadians))
    emailGroup.rotation.set(0, 0, contactRadians)
    emailGroup.position.x = contactOrbit.position.x + (emailMoonRadius * Math.sin((elapsedTime * emailSpeed) + emailOffset))
    emailGroup.position.z = contactOrbit.position.z + (emailAmplitude * Math.cos((elapsedTime * emailSpeed) + emailOffset))
    emailGroup.position.y = contactOrbit.position.y + (emailAmplitude * Math.sin(contactRadians) * Math.sin((elapsedTime * emailSpeed) + emailOffset))

    // photo moon orbits
    cameraMesh.position.x = photographyOrbit.position.x
    cameraMesh.position.y = photographyOrbit.position.y
    cameraMesh.position.z = photographyOrbit.position.z

    if(petOrbitPath){
        petOrbitPath.position.x = photographyOrbit.position.x
        petOrbitPath.position.y = photographyOrbit.position.y
        petOrbitPath.position.z = photographyOrbit.position.z
    }

    const petMoonRadius = (Math.sin(photoRadians) * petAmplitude) / (Math.tan(photoRadians)) 
    dogModel.rotation.set(0,0,photoRadians)
    dogModel.position.x = photographyOrbit.position.x + (petMoonRadius * Math.sin((elapsedTime * petSpeed) + petOffset)) // X position of the moon
    dogModel.position.z = photographyOrbit.position.z + (petAmplitude * Math.cos((elapsedTime * petSpeed) + petOffset)) // Z position of the moon
    dogModel.position.y = photographyOrbit.position.y + (petAmplitude * Math.sin(photoRadians) * Math.sin((elapsedTime * petSpeed) + petOffset))

    if(productOrbitPath){
        productOrbitPath.position.x = photographyOrbit.position.x
        productOrbitPath.position.y = photographyOrbit.position.y
        productOrbitPath.position.z = photographyOrbit.position.z
    }

    // Calculate the moon's position around photographyOrbit
    const productMoonRadius = (Math.sin(photoRadians) * productAmplitude) / (Math.tan(photoRadians)) 
    productMoon.position.x = photographyOrbit.position.x + (productMoonRadius * Math.sin((elapsedTime * productSpeed) + productOffset)) // X position of the moon
    productMoon.position.z = photographyOrbit.position.z + (productAmplitude * Math.cos((elapsedTime * productSpeed) + productOffset)) // Z position of the moon
    productMoon.position.y = photographyOrbit.position.y + (productAmplitude * Math.sin(photoRadians) * Math.sin((elapsedTime * productSpeed) + productOffset)) // Y position based on tilt

    //console.log(productAmplitude * Math.sin(photoAngle * (Math.PI/180)))
    if(wildlifeOrbitPath){
        wildlifeOrbitPath.position.x = photographyOrbit.position.x
        wildlifeOrbitPath.position.y = photographyOrbit.position.y
        wildlifeOrbitPath.position.z = photographyOrbit.position.z
    }

    const wildlifeMoonRadius = (Math.sin(photoRadians) * wildlifeAmplitude) / (Math.tan(photoRadians))

    wildlifeMoon.position.x = photographyOrbit.position.x + (wildlifeMoonRadius * Math.sin((elapsedTime * wildlifeSpeed) + wildlifeOffset))
    wildlifeMoon.position.z = photographyOrbit.position.z + (wildlifeAmplitude * Math.cos((elapsedTime * wildlifeSpeed) + wildlifeOffset))
    wildlifeMoon.position.y = photographyOrbit.position.y + (wildlifeAmplitude * Math.sin(photoRadians) * Math.sin((elapsedTime * wildlifeSpeed) + wildlifeOffset))
    
    if(portraitOrbitPath){
        portraitOrbitPath.position.x = photographyOrbit.position.x
        portraitOrbitPath.position.y = photographyOrbit.position.y
        portraitOrbitPath.position.z = photographyOrbit.position.z
    }

    const portraitMoonRadius = (Math.sin(photoRadians) * portraitAmplitude) / (Math.tan(photoRadians))

    portraitMoon.position.x = photographyOrbit.position.x + (portraitMoonRadius * Math.sin((elapsedTime * portraitSpeed) + portraitOffset))
    portraitMoon.position.z = photographyOrbit.position.z + (portraitAmplitude * Math.cos((elapsedTime * portraitSpeed) + portraitOffset))
    portraitMoon.position.y = photographyOrbit.position.y + (portraitAmplitude * Math.sin(photoRadians) * Math.sin((elapsedTime * portraitSpeed) + portraitOffset))
    
    if(landscapeOrbitPath){
        landscapeOrbitPath.position.x = photographyOrbit.position.x
        landscapeOrbitPath.position.y = photographyOrbit.position.y
        landscapeOrbitPath.position.z = photographyOrbit.position.z
    }

    const landscapeMoonRadius = (Math.sin(photoRadians) * landscapeAmplitude) / (Math.tan(photoRadians))

    landscapeMoon.position.x = photographyOrbit.position.x + (landscapeMoonRadius * Math.sin((elapsedTime * landscapeSpeed) + landscapeOffset))
    landscapeMoon.position.z = photographyOrbit.position.z + (landscapeAmplitude * Math.cos((elapsedTime * landscapeSpeed) + landscapeOffset))
    landscapeMoon.position.y = photographyOrbit.position.y + (landscapeAmplitude * Math.sin(photoRadians) * Math.sin((elapsedTime * landscapeSpeed) + landscapeOffset))

    //anim
    animMesh.position.x = animOrbit.position.x
    animMesh.position.y = animOrbit.position.y
    animMesh.position.z = animOrbit.position.z

    const animMoonAOrbitRadius = (Math.sin(animTilt) * mayaAmplitude) / (Math.tan(animTilt))
    animMoonA.position.x = animOrbit.position.x + (animMoonAOrbitRadius * Math.sin((elapsedTime * mayaSpeed) + mayaOffset))
    animMoonA.position.z = animOrbit.position.z + (mayaAmplitude * Math.cos((elapsedTime * mayaSpeed) + mayaOffset))
    animMoonA.position.y = animOrbit.position.y + (mayaAmplitude * Math.sin(animTilt) * Math.sin((elapsedTime * mayaSpeed) + mayaOffset))

    const animMoonBOrbitRadius = (Math.sin(animTilt) * aeAmplitude) / (Math.tan(animTilt))
    animMoonB.position.x = animOrbit.position.x + (animMoonBOrbitRadius * Math.sin((elapsedTime * aeSpeed) + aeOffset))
    animMoonB.position.z = animOrbit.position.z + (aeAmplitude * Math.cos((elapsedTime * aeSpeed) + aeOffset))
    animMoonB.position.y = animOrbit.position.y + (aeAmplitude * Math.sin(animTilt) * Math.sin((elapsedTime * aeSpeed) + aeOffset))
    
    if(mayaOrbitPath){
        mayaOrbitPath.position.x = animOrbit.position.x
        mayaOrbitPath.position.y = animOrbit.position.y
        mayaOrbitPath.position.z = animOrbit.position.z
    }


    if(aeOrbitPath){
        aeOrbitPath.position.x = animOrbit.position.x
        aeOrbitPath.position.y = animOrbit.position.y
        aeOrbitPath.position.z = animOrbit.position.z
    }
    // Raycaster
    raycaster.setFromCamera(mouse, camera)

    const intersects = raycaster.intersectObjects(clickable)

    for(const obj of clickable){
        obj.scale.set(1,1,1)
    }

    for(const intersect of intersects){
        intersect.object.scale.set(1.5, 1.5, 1.5)
    }

    if(intersects.length){ 
        if(currentIntersect === null){ 
            console.log("enter") 
             
        } 
        currentIntersect = intersects[0] 
    } 
    else{ 
        if(currentIntersect){ 
            //mouse leave 
            console.log("leave") 
        } 
        currentIntersect = null 
    }

    //flyControls.update(delta)
    orbitControls.update(delta)

    renderer.render(scene, camera) 
    
    window.requestAnimationFrame(tick) 
} 
tick()
